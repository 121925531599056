<template>
  <div class="input-search-data-table">
    <v-text-field
      v-model="dataTitleModel"
      :label="$t('register_data.placeholder_title')"
      :placeholder="$t('register_data.placeholder_title')"
      solo
      hide-details
      height="28"
      class="input-search-title"
      :class="isReadOnly ? 'disabled_title' : ''"
      id="register-title"
    ></v-text-field>
    <div class="clear-icon" v-if="dataTitleModel && !isReadOnly">
      <img class="clear-data-input-search" src="@/assets/icons/close-circle-search.svg" alt=""
        @click="clearDataTitleModel" />
      <img class="clear-data-input-search-active" src="@/assets/icons/close-circle-search-active.svg" alt=""
        @click="clearDataTitleModel" />
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {};
  },
  props   : {
    dataTitle: {
      type   : String,
      default: null
    },
    isReadOnly: {
      type: [Boolean, Number],
      default: false
    },
  },
  computed: {
    dataTitleModel: {
      get() {
        return this.dataTitle;
      },
      set(value) {
        this.$emit("update:dataTitle", value);
      }
    }
  },
  methods: {
    clearDataTitleModel() {
      if(this.isReadOnly) {
        return ;
      } else {
        this.$emit("update:dataTitle", "");
      }
    }
  }
};
</script>
<style lang="scss">
.input-search-data-table {
  .input-search-title {
    .v-input__control {
        .v-input__slot {
          padding: 0 !important;
          .v-text-field__slot {
            border: 1px solid #E3EDED;
            box-shadow: inset 0px 2px 2px rgba(160, 181, 186, 0.15), inset 0px 2px 6px rgba(160, 181, 186, 0.5);
            border-radius: 4px;
            line-height: 18px !important;
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            letter-spacing: 0.03em;
            color: #404D50 !important;
            input{
              padding: 12px 22px 12px 12px;
              height: 28px;
              font-weight: 700;
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: #404D50;
              border-radius: 4px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            label {
              padding: 0 12px;
              line-height: 18px !important;
              font-style: normal;
              font-weight: 700;
              font-size: 11px;
              letter-spacing: 0.03em;
              color: #404D50;
            }
          }
        }
      }
  }
  .disabled_title {
    .v-input__control {
      pointer-events: none;
      .v-input__slot {
          .v-text-field__slot {
            input{
              background: $bgLight;
              border: 1px solid rgba(42, 42, 48, 0.1);
            }
          }
        }
    }
  }

  .v-input{
    font-size: 11px;
    line-height: 18px;
    .v-label{
      font-size: 11px;
      line-height: 18px;
    }
  }
}
.input-search-title.v-input--is-focused {
  .v-input__control .v-text-field__slot{
    line-height: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.03em;
    color: #404D50;
  }
}
.input-search-title.v-input input:focus{
  border: 2px solid $blueMid;
}
</style>
<style lang="scss" scoped>
.input-search-data-table {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: auto;
  height: 26px;
  background: #ffffff;
  border: 1px solid #e3eded;
  box-shadow: inset 0px 2px 2px rgba(160, 181, 186, 0.15), inset 0px 2px 6px rgba(160, 181, 186, 0.5);
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 3px;
  position: relative;

  .clear-icon {
      position: absolute;
      width: 16px;
      height: 16px;
      margin: 6px;
      top: 0;
      right: 0;
      .clear-data-input-search {
        display: block;
      }
      .clear-data-input-search-active {
        display: none;
      }
      &:hover {
        .clear-data-input-search {
          display: none;
        }
        .clear-data-input-search-active {
          display: block;
        }
      }
    }
}

::v-deep .v-input__slot {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  min-height: 28px;

  &::before {
    border-bottom: unset;
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .input-search-data-table {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
<style>
.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 10px);
}
</style>
