import { insert, createIndexedDB, findOne, truncate, getAll, findMany, hasData } from '@/concerns/index-db'

const DB_NAME = 'db_templates'
const DB_ITEM_NAME = 'db_item_name'
const DB_NAME_ALL = 'db_templates_all'
const DB_LIST_ITEM_NAME = 'db_item_name_all'
const DB_BASIC_UNIT_FILTER_DEFAULT = 'db_basic_unit_filter_default'

const DB_BASIC = 'DB_BASIC_UNIT'
const DB_BASIC_ALL = 'DB_BASIC_UNIT_ALL'

const TB_DATA = 'tb_data'
const TB_DB_UNIT = 'tb_db_unit'
const TB_ITEM_NAME = 'tb_item_name'
const TB_LIST_ITEM_NAME = 'tb_list_item_name'
const TB_BASIC_UNIT_FILTER_DEFAULT = 'tb_basic_unit_filter_default'

const TB_BASIC = 'tb_basic_unit'
const TB_BASIC_ALL = 'tb_basic_unit_all'

//logic CRUD for row num pattern
export const insertDataBasicByRowNumPattern = async (dataRows, isTruncate = true, rowNumPattern) => {
  if(isTruncate) {
    await createIndexedDB(`${DB_BASIC}_${rowNumPattern}`, `${TB_BASIC}_${rowNumPattern}`)
    await truncate(`${DB_BASIC}_${rowNumPattern}`, `${TB_BASIC}_${rowNumPattern}`)
  }

  return await insert(`${DB_BASIC}_${rowNumPattern}`, `${TB_BASIC}_${rowNumPattern}`, dataRows)
}

export const findOneByIdOfDataByRowNumPattern = async (id, rowNumPattern) => {
  return await findOne(`${DB_BASIC}_${rowNumPattern}`, `${TB_BASIC}_${rowNumPattern}`, id)
}

export const findManyByIdAndRowNumPattern = async (ids, rowNumPattern) => {
  return await findMany(`${DB_BASIC}_${rowNumPattern}`, `${TB_BASIC}_${rowNumPattern}`, ids)
}

export const getAllRecordOfDataByRowNumPattern = async (rowNumPattern) => {
  return await getAll(`${DB_BASIC}_${rowNumPattern}`, `${TB_BASIC}_${rowNumPattern}`)
}
//end


//logic CRUD for list all of row num pattern
//hasData
export const checkTableHasDataByRowNum = async (rowNumPattern) => {
  return await hasData(`${DB_BASIC_ALL}_${rowNumPattern}`, `${TB_BASIC_ALL}_${rowNumPattern}`)
}

const getDbNameAndTableNameByKeyPath = (keyPath = 'id') => {
  let dbName = DB_BASIC_ALL
  let tableName = TB_BASIC_ALL
  if(keyPath === 'itemNameNew') {
    dbName += '_by_name'
    tableName += '_by_name'
  }
  return [dbName, tableName]
}

export const insertAllDataBasic = async (dataRows, keyPath = 'id') => {
  const [dbName, tableName] = getDbNameAndTableNameByKeyPath(keyPath)
  await createIndexedDB(dbName, tableName, keyPath)
  await truncate(dbName, tableName)
  return await insert(dbName, tableName, dataRows, keyPath)
}

export const findOneByIdOfAllBasic = async (id, keyPath = 'id') => {
  const [dbName, tableName] = getDbNameAndTableNameByKeyPath(keyPath)
  return await findOne(dbName, tableName, id)
}

export const findManyByIdListOfAllBasic = async (ids, keyPath = 'id', isSpliceEmpty = false) => {
  const [dbName, tableName] = getDbNameAndTableNameByKeyPath(keyPath)

  return await findMany(dbName, tableName, ids, isSpliceEmpty)
}

export const getAllRecordOfAllBasicUnit = async (keyPath = 'id') => {
  const [dbName, tableName] = getDbNameAndTableNameByKeyPath(keyPath)
  return await getAll(dbName, tableName)
}
//end



export const insertOfData = async (dataRows, isTruncate = true) => {
  if(isTruncate) {
    await createIndexedDB(DB_NAME, TB_DATA)
    await truncate(DB_NAME, TB_DATA)
  }

  return await insert(DB_NAME, TB_DATA, dataRows)
}

export const findOneByIdOfData = async (id) => {
  return await findOne(DB_NAME, TB_DATA, id)
}

export const findManyByIdListOfData = async (ids) => {
  return await findMany(DB_NAME, TB_DATA, ids)
}

export const getAllRecordOfData = async () => {
  return await getAll(DB_NAME, TB_DATA)
}
//end

export const insertOfDbUnit = async (dataRows) => {
  await createIndexedDB(DB_NAME, TB_DB_UNIT)
  await truncate(DB_NAME, TB_DB_UNIT)

  return await insert(DB_NAME, TB_DATA, dataRows)
}

export const findOneByIdOfDbUnit = async (id) => {
  return await findOne(DB_NAME, TB_DB_UNIT, id)
}

export const getAllRecordOfDbUnit = async () => {
  return await getAll(DB_NAME, TB_DB_UNIT)
}

// logic for item name
export const insertOfDbUnitByName = async (dataRows) => {
  await createIndexedDB(DB_ITEM_NAME, TB_ITEM_NAME, 'itemNameNew')
  await truncate(DB_ITEM_NAME, TB_ITEM_NAME)

  return await insert(DB_ITEM_NAME, TB_ITEM_NAME, dataRows, 'itemNameNew')
}

export const findOneByIdOfDbUnitByName = async (id) => {
  return await findOne(DB_ITEM_NAME, TB_ITEM_NAME, id)
}

export const getAllRecordOfDbUnitByName = async () => {
  return await getAll(DB_ITEM_NAME, TB_ITEM_NAME)
}

//TB_LIST_ITEM_NAME
export const insertOfUnitByName = async (dataRows) => {
  await createIndexedDB(DB_LIST_ITEM_NAME, TB_LIST_ITEM_NAME, 'itemNameNew')
  await truncate(DB_LIST_ITEM_NAME, TB_LIST_ITEM_NAME)

  return await insert(DB_LIST_ITEM_NAME, TB_LIST_ITEM_NAME, dataRows, 'itemNameNew')
}

export const findOneByByItemNameCustomInPullDown = async (id) => {
  return await findOne(DB_LIST_ITEM_NAME, TB_LIST_ITEM_NAME, id)
}


//db for basicUnitFilterDataDefault

export const insertDefaultFilterList = async (dataRows) => {
  await createIndexedDB(DB_BASIC_UNIT_FILTER_DEFAULT, TB_BASIC_UNIT_FILTER_DEFAULT)
  await truncate(DB_BASIC_UNIT_FILTER_DEFAULT, TB_BASIC_UNIT_FILTER_DEFAULT)

  return await insert(DB_BASIC_UNIT_FILTER_DEFAULT, TB_BASIC_UNIT_FILTER_DEFAULT, dataRows)
}

export const findOneItemFilterById = async (id) => {
  return await findOne(DB_BASIC_UNIT_FILTER_DEFAULT, TB_BASIC_UNIT_FILTER_DEFAULT, id)
}

export const deleteAllDbIndexed = async () => {
  await truncate(DB_NAME, TB_DATA)
  await truncate(DB_NAME_ALL, TB_DB_UNIT)
  await truncate(DB_ITEM_NAME, TB_ITEM_NAME)
  await truncate(DB_LIST_ITEM_NAME, TB_LIST_ITEM_NAME)
  await truncate(DB_BASIC_UNIT_FILTER_DEFAULT, TB_BASIC_UNIT_FILTER_DEFAULT)
}

