export const addItemToPulldown = (dataProps) => {
  // add new item to basic unit pulldown
  const { flexgrid, itemId, itemSource, pulldownBinding, dataMap, wjGrid } = dataProps;
  const column = flexgrid.getColumn(pulldownBinding);
  if (column && column.index >= 0) {
    column.editor.itemsSource.push({
      id: itemId,
      [dataMap.value]: itemSource,
    });
    column.dataMap = new wjGrid.DataMap(column.editor.itemsSource, dataMap.key, dataMap.value);
  }
};

export const addManyItemsToPulldown = (dataProps) => {
  // add new item to basic unit pulldown
  const { flexgrid, listItems, pulldownBinding, dataMap, wjGrid } = dataProps;
  const column = flexgrid.getColumn(pulldownBinding);
  if (column && column.index >= 0) {
    column.editor.itemsSource.push(listItems);
    column.dataMap = new wjGrid.DataMap(column.editor.itemsSource, dataMap.key, dataMap.value);
  }
};

export const clearItemToPulldown = (dataProps) => {
  // add new item to basic unit pulldown
  const { flexgrid, pulldownBinding, dataMap, wjGrid } = dataProps;
  if(!flexgrid) return
  const column = flexgrid.getColumn(pulldownBinding);
  if (column && column.index >= 0) {
    column.editor.itemsSource = []
    column.dataMap = new wjGrid.DataMap([], dataMap.key, dataMap.value);
  }
}