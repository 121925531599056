<template>
  <div class="button-action-table 12" :class="{ disabled: disabledUndoRedoAction(), 'height-fit-content': maxWidth, 'template-ui': displayAsTemplateDetails && !isFullScreen}">
    <div class="button-submit-area" v-if="displayAsideaSoureDBScreen">
        <v-btn depressed class="button-save template-btn" :class="maxWidth" @click="handleCreateNewIdeaSourceDb()" id="db-source-btn"> {{ $t("idea_db_source.button_add_new_idea_db_source") }} </v-btn>
    </div>
    <div class="button-submit-area" v-if="displayAsActionDataMerge">
      <v-btn depressed class="" :class="maxWidth" @click="handleNavigateToFormManagement()" id="data-merge"> {{ $t("primary_data_merge.button_to_form_data_merge") }} </v-btn>
    </div>
    <div class="button-submit-area" v-if="displayAsActionCreateFormDataMerge">
      <v-btn depressed class="button-save template-btn" :class="maxWidth" @click="handleCreateNewFormDataMerge()" id="create-data-merge"> {{ $t("primary_data_merge.button_add_new_form_data_merge") }} </v-btn>
    </div>
    <div class="button-submit-area" v-if="displaySettingTarget && isFullScreen">
        <v-btn depressed class="button-save save-register-button" :class="{'disabled': isDisableBySetting, 'max-width-unset': maxWidth }" @click="handleSaveData" id="save-btn"> {{ $t('icp_target_integration.button_integrate') }} </v-btn>
    </div>
    <div class="button-submit-area action-left " v-if="leftSideActionCount > 0 && !isPrimaryDataDetail">
      <v-btn v-if="displayWithDataApproved && !isSummaryScoreRoute" depressed class="button-save save-register-button" :class="{'disabled':!isEditedData  || isDisableBySetting, 'max-width-unset': maxWidth }" @click="handleUpdateApprovedData">
        <img src="@/assets/images/emissions/button/save.svg" alt="" class="button-icon"/>{{ $t("register_data.button_keep") }}
      </v-btn>
      <v-btn v-if="isShowSummaryScore && isShowNotePcaf" depressed class="button-save save-register-button" @click="handleGotoScoreSummary(true)">
        {{ $t("setting_pcaf.button_goto_summary_screen") }}
      </v-btn>
      <v-btn v-if="isSummaryScoreRoute && !isShowSummaryScore" depressed class="button-save save-register-button" @click="handleGotoScoreSummary(false)">
        {{ $t("setting_pcaf.button_back_emission_view") }}
      </v-btn>
      <v-btn v-if="showSaveRegister && !displayAsideaSoureDBScreen && !displaySettingTarget && isShowButtonSave" depressed class="button-save save-register-button" :class="{'disabled':!isHasData  || isDisableBySetting, 'max-width-unset': maxWidth }" @click="handleSaveData" id="save-btn">
        <img src="@/assets/images/emissions/button/save.svg" alt="" class="button-icon" />{{ $t("register_data.button_keep") }}
      </v-btn>
      <v-btn v-if="$route.path.includes('emissions/view') && windowWidth >= 1024 && isAllowExport" depressed class="button-save export-btn" :class="maxWidth" @click="handleShowExportPopup" id="export-btn">
        <img src="@/assets/images/emissions/button/export.svg" alt="" class="saveicon" style="margin-right: 6px;" />
        {{ $t("register_data.button_export_details") }}
      </v-btn>
      <v-btn v-if="displayAsNewRegisterDataAndDraft" depressed class="button-save-template" :class="maxWidth" :disabled="!isHasData  || isDisableBySetting" @click="handleSaveTemplate" id="template-btn">{{ $t("register_data.button_template_preservation") }}</v-btn>
      <v-btn v-if="displayAsTemplateDetails && displayAsOwnerTemplate" depressed class="button-save" :class="maxWidth" :disabled="!isHasData || isDisableBySetting" @click="handleOverrideTemplate" id="update-template-btn">
        <img v-if="!isHasData || isDisableBySetting" src="@/assets/images/emissions/button/save_disabled.svg" alt="" />
        <img v-else src="@/assets/images/emissions/button/save.svg" alt="" />
        {{ $t("register_data.button_save_override") }}
      </v-btn>
      <v-btn v-if="displayAsTemplateDetails && !displayAsOwnerTemplate" depressed class="button-save" :class="maxWidth" :disabled="!isHasData" @click="handleCloneTemplate" id="clone-template-btn">{{ $t("register_data.button_save_new_template") }}</v-btn>
      <v-btn v-if="showDelete" depressed class="button-delete" :class="maxWidth" @click="handleDeleteData" :disabled="isDisableBySetting" id="delete-btn">
        <img v-if="isDisableBySetting" src="@/assets/images/emissions/button/delete_disable.svg" alt="" />
        <img v-else src="@/assets/images/emissions/button/delete.svg" alt="" />
        {{ $t("register_data.button_delete") }}
      </v-btn>
      <check-box-home-standard
        v-if="displayAsNewRegisterDataAndDraft"
        @change="handleShowError"
        v-model="isShowErrorTablePcaf"
        :label="$t('setting_pcaf.button_error_register_data')" 
        class="button-save save-register-button handle-show-error" />
    </div>
    <div class="button-submit-area action-right" v-if="rightSideActionCount">
      <v-btn v-if="displayAsTemplateDetails && isFullScreen" depressed class="button-save template-btn" :class="maxWidth" :disabled="!isHasData || isDisableBySetting" @click="handleSaveData" id="save-btn">{{ $t("register_data.button_keep") }}</v-btn>
      <v-btn v-if="showSubmitRegister && !displayWidthDataSupplier"  depressed class="button-submit submit-register-button" :class="maxWidth" :disabled="!isHasData || isDisableBySetting" @click="handleSubmitData" id="submit-btn">{{ $t("register_data.button_application") }}</v-btn>
      <v-btn v-if="displayAsReviewer() && unCheckedPermission" depressed class="button-submit" :class="maxWidth" :disabled="!isHasData || isDisableBySetting || isDisableByDeActive" @click="handleReviewingData" id="approve-btn">{{ $t("register_data.button_accept_request") }}</v-btn>
      <v-btn v-if="displayAsOwner" depressed class="button-submit" :class="maxWidth" :disabled="isCancelSubmitted || isDisableBySetting" @click="handleCancelSubmitted" id="cancel-submit-btn">{{ $t("register_data.button_cancel_request") }}</v-btn>
      <v-btn v-if="displayAsAccepterCancelSubmitted  && unCheckedPermission" :disabled="isDisableBySetting" depressed class="button-submit" :class="maxWidth" @click="handleAcceptCancelSubmitted" id="accept-cancel-submit-btn">{{ $t("register_data.button_accept_cancel_request") }}</v-btn>
      <v-btn v-if="displayWidthDataSupplier" depressed class="button-submit" :class="maxWidth" @click="handleSendDataSupplier()"> 送信 </v-btn>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import { ROLE } from '@/constants/role';
import { STATUS_SUBMIT, STATUS_FIELD, STATUS_CANCEL_SUBMMITED } from '@/constants/status';
import { ACTION_HEADER_TABLE, SCREEN_NAME_NAVIGATE } from '@/constants/registerData';
import { getClassesMaxWidthActionTable } from '@/utils/multiLanguage'
import CheckBoxHomeStandard from '@/components/dashboard/home-standard/checkbox-standard';

export default {
  data() {
    return {
      socketAccessInterval: null,
      listUsersAccessTemplateData: [],
      isCurrentUser: false,
      isCurrentAdmin: false,
      isCurrentApprover: false,
      disabledUndo: true,
      disabledRedo: true,
      enableInRoute: [
        ROUTES.USER_MANAGEMENT,
        ROUTES.VIEWER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.CREATE_DATA_CUSTOMIZE,
        ROUTES.GROUP_ENTERPRISE_MANAGEMENT,
        ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA,
        ROUTES.PRODUCTS_EMISSION + ROUTES.USER_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.VIEWER_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.FACILITY_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
        ROUTES.LIST_IDEA_DB_SOURCE,
        ROUTES.PRIMARY_DATA_MERGE,
        ROUTES.FORM_PRIMARY_DATA_MERGE,
        ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_PRIMARY_DATA_MERGE,
        ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_FORM_PRIMARY_DATA_MERGE,
        ROUTES.SETTING_TARGET,
      ],
      enableActionRegisterData: [ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA],
      listRoutesDetails: [
        ROUTES.LIST_EMISSION_SUBMITTED,
        ROUTES.LIST_EMISSION_TEMPLATES,
        ROUTES.LIST_EMISSION_TEMPORARY,
        ROUTES.LIST_APPROVAL,
        ROUTES.PRIMARY_DATA_MERGE_DETAIL
      ],
      windowWidth: window.innerWidth,
      maxWidth: getClassesMaxWidthActionTable(),
      isShowErrorTablePcaf: false
    };
  },
  components: { CheckBoxHomeStandard },
  props: {
    isHasData: {
      type: Boolean,
      default: false,
    },
    workFlowData: {
      type: Object,
      default: () => {},
    },
    unCheckPermission: {
      type: Boolean,
      default: false,
    },
    isEditedData: {
      type: [Number, Boolean],
      default: 0,
    },
    isDisableBySetting: {
      type: Boolean,
      default: false,
    },
    isDisableButtonStatusTemplate: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    settingPcafBySelectMonth: {
      type: Boolean,
      default: false,
    },
    isShowNotePcaf: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('commonApp', ['statusAction']),
    ...mapState('actionsTable', ['statusBtn']),
    ...mapState('userData', ['currentUser', 'planType']),
    showSubmitRegister(){
      return !this.displayAsReviewer() &&
        !this.displayAsSettingScreen() &&
        !this.displayAsOwner &&
        !(this.displayAsTemplateDetails && !this.isFullScreen) &&
        !this.displayAsAccepterCancelSubmitted &&
        !this.displayAsDataApproved &&
        !this.displayAsDataBeingReturned
    },

    showDelete(){
      return !this.displayAsReviewer() &&
        !this.displayAsSettingScreen() &&
        this.displayAsOwnerTemplateInFullmode &&
        !this.displayAsOwner &&
        !this.displayAsAccepterCancelSubmitted &&
        !this.displayAsDataApproved &&
        !this.displayWidthDataSupplier
    },
    showSaveRegister(){
      return this.displayAsNewRegisterDataAndDraft ||
        this.displayAsSettingScreen() ||
        this.displayAsDataBeingReturned
    },
    isNeedCompressed(){
      return (this.leftSideActionCount + this.rightSideActionCount) == 1
    },
    leftSideActionCount(){
     const actionsDisplay = [
      Boolean(this.displayWithDataApproved),
      Boolean(this.showSaveRegister),
      Boolean(this.displayAsNewRegisterDataAndDraft),
      Boolean(this.displayAsTemplateDetails && this.displayAsOwnerTemplate),
      Boolean(this.displayAsTemplateDetails && !this.displayAsOwnerTemplate),
      Boolean(this.showDelete),
      Boolean(this.$route.path.includes('emissions/view') && this.windowWidth >= 1024),
      ].filter(Boolean).length;
      return actionsDisplay;
    },

    rightSideActionCount(){
     const actionsDisplay = [
      Boolean(this.displayAsTemplateDetails),
      Boolean(this.showSubmitRegister),
      Boolean(this.displayAsReviewer()),
      Boolean(this.displayAsOwner),
      Boolean(this.displayAsAccepterCancelSubmitted)
      ].filter(Boolean).length;
      return actionsDisplay ;
    },
    checkHasUserAccessTemplateDetail() {
      return this.listUsersAccessTemplateData.length > 0;
    },
    displayAsNewRegisterDataAndDraft() {
      return (
        (!this.listRoutesDetails.includes(this.$route.params?.scope) &&
          this.$route.name === 'NewDetailTemplate' &&
          Object.keys(this.workFlowData || {}).length === 0) ||
        this.workFlowData?.status === STATUS_FIELD.STATUS_DRAFT
      );
    },
    displayAsPcafPattern() {
      return this.workFlowData?.scope == 3 && this.workFlowData?.category == 15 && [5,6,7,8,9,10,11].includes(this.workFlowData?.pattern_id);
    },
    displayAsDataBeingReturned() {
      return (
        this.workFlowData?.status === STATUS_FIELD.STATUS_RETURN ||
        this.workFlowData?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN
      );
    },
    displayAsOwner() {
      return !!(
        this.workFlowData?.is_owner &&
        (this.workFlowData?.status === STATUS_SUBMIT || this.workFlowData?.status === STATUS_CANCEL_SUBMMITED)
      );
    },
    isCancelSubmitted() {
      return this.workFlowData?.status === STATUS_CANCEL_SUBMMITED;
    },
    displayAsAccepterCancelSubmitted() {
      return !!(
        this.workFlowData?.status === STATUS_CANCEL_SUBMMITED &&
        (this.workFlowData?.is_approver || this.currentUser?.user?.role_id === ROLE.ADMIN)
      );
    },
    displayWithDataApproved() {
      return (
        (this.currentUser?.user?.role_id === ROLE.ADMIN &&
          (this.workFlowData?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED ||
            this.workFlowData?.status === STATUS_FIELD.STATUS_APPROVED)) ||
        (this.currentUser?.user?.role_id === ROLE.ADMIN && this.$route.fullPath.includes('/emissions/view/'))
      );
    },
    displayAsDataApproved() {
      return (
        this.workFlowData?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED ||
        this.workFlowData?.status === STATUS_FIELD.STATUS_APPROVED ||
        this.$route.fullPath.includes('/emissions/view/') || 
        this.$route.fullPath.includes('/product-emission/process-data-link')
      );
    },
    isSummaryScoreRoute() {
      return this.$route.path.includes('emissions/view/score-summary');
    },
    displayAsTemplateDetails() {
      return this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES;
    },
    displayAsOwnerTemplate() {
      return !(this.workFlowData?.template_type && this.workFlowData?.is_shared);
    },
    displayAsOwnerTemplateInFullmode() {
      if (!this.displayAsTemplateDetails) {
        return true;
      }

      if (this.workFlowData?.template_type && this.workFlowData?.is_shared) {
        return false;
      }

      return this.isFullScreen;
    },

    theTemplateIsShared() {
      return this.workFlowData?.template_type === 1;
    },
    unCheckedPermission() {
      return !this.unCheckPermission
    },
    displayAsideaSoureDBScreen() {
      return this.$route.path === ROUTES.LIST_IDEA_DB_SOURCE;
    },
    displayAsActionDataMerge() {
      return [ROUTES.PRIMARY_DATA_MERGE, ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_PRIMARY_DATA_MERGE].includes(this.$route.path);
    },
    displayAsActionCreateFormDataMerge() {
      return (
        this.$route.path === ROUTES.FORM_PRIMARY_DATA_MERGE ||
        this.$route.path === ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_FORM_PRIMARY_DATA_MERGE
      );
    },
    displayWidthDataSupplier() {
      return this.$route.path.includes(ROUTES.SUPPLIER);
    },
    displaySettingTarget() {
      return this.$route.path === ROUTES.SETTING_TARGET;
    },
    isDisableByDeActive() {
      return !this.workFlowData?.is_active;
    },
    isShowSummaryScore() {
      return this.isShowNotePcaf && !this.isSummaryScoreRoute && this.$route.fullPath.includes('/emissions/view/');
    },

    isPrimaryDataDetail () {
      return this.$route.fullPath.includes('/product-emission/process-data-link')
    },
    isShowButtonSave() {
      return !(this.$route.path === ROUTES.PRODUCTS_EMISSION + ROUTES.PRIMARY_DATA_MERGE);
    },
    isAllowExport() {
      return this.planType?.is_export_cl7;
    }
  },
  mounted() {
    this.disabledUndoRedoAction();
    this.displayAsSettingScreen();
    this.displayAsReviewer();
    if (this.theTemplateIsShared && !this.workFlowData.is_owner ) {
      this.socketAccessInterval = setInterval(() => {
        this.$socket.emit('accessedDetailTemplate', this.workFlowData.id);
      }, 1000);
    }
    this.$emit('needCompressed', this.isNeedCompressed);
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  beforeDestroy() {
    if (this.theTemplateIsShared) {
      this.$socket.emit('leavingTemplateDetail', this.workFlowData?.id);
    }

    clearInterval(this.socketAccessInterval);
  },
  watch:{
    isNeedCompressed (val){
      this.$emit('needCompressed', val)
    }
  },
  methods: {
    ...mapActions('actionsTable', ['actionStartUpdate']),
    handleShowError(value) {
      this.isShowErrorTablePcaf = value
      this.$emit('onHandleShowError', value);
    },
    handleNavigateToFormManagement() {
      if (this.$route.path === ROUTES.PRIMARY_DATA_MERGE) return this.$router.push({ path: ROUTES.FORM_PRIMARY_DATA_MERGE });
      return this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_FORM_PRIMARY_DATA_MERGE});
    },
    handleNavigateToExportDetails() {
      return this.$emit('onNavigateToScreen', SCREEN_NAME_NAVIGATE.GLOBAL_WARMING_DETAILS)
    },
    handleCreateNewFormDataMerge() {
      // TODO: Change route name
      if (this.$route.path === ROUTES.FORM_PRIMARY_DATA_MERGE) return this.$router.push({ path: ROUTES.CREATE_FORM_LINK_PRIMARY_DATA });
      return this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_CREATE_FORM_LINK_PRIMARY_DATA});
    },
    allowActionUndo() {
      return this.statusBtn.undo;
    },
    allowActionRedo() {
      return this.statusBtn.redo;
    },
    disabledUndoRedoAction() {
      return !(
        this.enableInRoute.includes(this.$route.path) ||
        this.$route.name === 'RegisterData' ||
        this.$route.name === 'NewDetailTemplate' ||
        this.$route.name === 'List primary data' ||
        this.$route.name === 'ScoreSummary' ||
        this.displayAsWarmingCategoryExportDetails ||
        this.$route.name === 'ProcessDataLink'
      );
    },
    displayAsReviewer() {
      return (
        ((this.currentUser?.user?.role_id === ROLE.APPROVAL && this.workFlowData?.is_approver) || this.currentUser?.user?.role_id === ROLE.ADMIN) &&
        this.workFlowData?.status === STATUS_SUBMIT
      );
    },
    displayAsSettingScreen() {
      return this.enableInRoute.includes(this.$route.path);
    },
    handleSaveData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.SAVE_TEMPRORA);
    },
    handleSaveTemplate() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.SAVE_TEMPLATE);
    },
    handleDeleteData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.DELETE_DATA, this.checkHasUserAccessTemplateDetail);
    },
    handleSubmitData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.SUBMIT_DATA);
    },
    handleOverrideTemplate() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.OVERRIDE_TEMPLATE, this.checkHasUserAccessTemplateDetail);
    },
    handleCancelSubmitted() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.CANCEL_SUBMITTED);
    },
    handleCloneTemplate() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.CLONE_TEMPLATE);
    },
    handleReviewingData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.REVIEWING_DATA);
    },
    handleAcceptCancelSubmitted() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED);
    },
    handleUpdateApprovedData() {
      this.$emit('onHandleActionTable', ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA);
    },
    handleShowExportPopup() {
      this.$emit('onHandleShowExportPopup', true);
    },
    handleGotoScoreSummary(value) {
      this.$emit('onHandleGotoScoreSummary', value);
    },
    handleCreateNewIdeaSourceDb() {
      this.$emit('onNavigateToCreateDbSource');
    },
    handleSendDataSupplier() {
      this.$emit('onSendDataSupplier');
    }
  },
  sockets: {
    accessedDetailTemplate(socketData) {
      if (
        socketData.template === this.workFlowData?.id &&
        this.theTemplateIsShared &&
        this.$socket.id !== socketData.socketId
      ) {
        if (this.listUsersAccessTemplateData) {
          this.listUsersAccessTemplateData = [socketData.socketId];
        } else {
          if(!this.listUsersAccessTemplateData.includes(socketData.socketId)) {
            this.listUsersAccessTemplateData.push(socketData.socketId);
          }
        }
      }
    },
    leavingTemplateDetail(socketData) {
      if (socketData.template === this.workFlowData?.id && this.theTemplateIsShared) {
        this.listUsersAccessTemplateData = this.listUsersAccessTemplateData.filter((user) => {
          return user !== socketData.socketId;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .custom-checkbox {
  margin-bottom: 0px;
  .label {
    color: $monoBlack;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.33px;
      text-transform: uppercase;
  }
  &:hover {
    opacity: 1;
    background-color: $goldLight !important;
    border: 1px solid rgba(42, 42, 48, 0.1) !important;
    box-shadow: 0px 0.181152001px 0.6340319514px 0px rgba(160, 181, 186, 0.48), 0px 0.500862062px 1.7530173063px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069px 4.2205901146px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
    cursor: pointer;
    .label {
      color: $monoWhite;
      cursor: pointer;
    }
  }
}
.button-action-table {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  &.template-ui {
    gap: unset;
  }

  .v-btn {
    &:before {
      background-color: inherit;
    }
    background: #f7f7f2;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;

    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #404d50;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 28px;
    padding: 4px 8px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    &:hover {
      &::before {
        opacity: 1;
        background-color: #A4A184;
        border: 1px solid rgba(42, 42, 48, 0.10);
        box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48),
          0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31),
          0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24),
          0px 4px 14px 0px rgba(160, 181, 186, 0.17);
      }
      color: #fff;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(36deg) brightness(99%) contrast(108%);
      }
    }
  }
  ::v-deep .v-btn {
    &.v-btn--disabled {
      background: $monoLight !important;
      color: #99A6A9 !important;
    }
  }
  .button-save {
    background: #f7f7f2;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;
    // font-family: "Source Han Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #404d50;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 28px;
    padding: 4px 8px;
    .v-btn__content {
      img {
        margin-right: 6px;
      }
    }
    &.disabled{
      pointer-events: none;
      cursor: default;
      color: $monoMid;
      background: $monoLight !important;
      .button-icon{
       filter: grayscale(1) opacity(0.5);
      }
    }
    &.export-btn {
      min-width: 106px;
      width: fit-content;
      display: flex;
      padding: 4px 8px 6px;
      gap: 6px;
    }
  }
  .save-register-button {
    min-width: 61px;
  }
  .button-save-template {
    &.disabled {
      background-color: $monoLight !important;
    }
  }
  .button-delete {
    min-width: 61px;
    .v-btn__content {
      img {
        margin-right: 6px;
      }
    }
    &.disabled{
      pointer-events: none;
      cursor: default;
      color: $monoMid;
      background: $monoLight !important;
      .button-icon{
       filter: grayscale(1) opacity(0.5);
      }
    }
  }
  .button-submit-area {
    flex-grow: 1;
    .submit-register-button {
      // max-width: 39px;
      min-width: 39px;
      // border: none;
      &.disabled {
        background-color: $monoLight !important;
        border: 1px solid rgba(42, 42, 48, 0.10);
      }
    }
    .button-submit {
      color: white;
      background: $goldMid;
      &.disabled {
        background-color: $monoLight !important;
      }
    }
    .button-save {
      &.template-btn {
        color: white;
        background: $goldMid;
        // max-width: 39px;
        min-width: 39px;
        &.disabled {
          background-color: $monoLight !important;
        }
      }
    }
    &.action-left{
      display: flex;
      grid-gap: 8px;
      flex-wrap: wrap;
      justify-content: left;
    }
    &.action-right{
      display: flex;
      grid-gap: 8px;
      flex-wrap: wrap;
      justify-content: right;
    }
  }

  &.disabled {
    display: none;
  }
}

.max-width-unset {
  max-width: unset !important;
}

@media (max-width: $desktop) {
  .button-action-table {
    width: 100%;
    align-items: unset;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  .height-fit-content {
    height: fit-content !important;
    // flex-direction: column;
    align-items: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .height-fit-content {
    height: fit-content !important;
  }
}
</style>
